body {
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  border-bottom: 2px solid #F00;
}

.footer {
  border-top: 1px solid #CCC;
  padding-top: 20px;
}

.App {
  margin: 16px 32px;
  display: inline-block;
}

TABLE.gameboard {
  border: 1px solid #CCC;
}

TABLE.gameboard TD {
  width: 64px;
  height: 64px;
  margin: 1px;
  background: #DDD;
}

.explanation {
  margin-top: 32px;
  padding: 10px;
  background: #CCC;
  text-align: center;
}

.explanation b {
  color: #090;
}

.explanation button {
  background: #090;
  border: 1px solid #333;
  padding: 8px;
  color: #FFF;
  font-weight: bold;
}

.explanation .lost {
  color: #F00;
  font-weight: bold;
}